import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class PrivacyRequestPortalCustomerFacingAuthGuardService implements CanActivate {
	constructor(private environment: Environment) {}

	public canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return of(true);
	}
}
