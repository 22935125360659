<app-kpa-spinner></app-kpa-spinner>
<h1>New Suggestion</h1>

<kpa-error-card
	mainTitle="Error"
	*ngIf="saveFailed">
	Something went wrong. Please call support
</kpa-error-card>
<kpa-status-card
	state="success"
	mainTitle="Success"
	*ngIf="saveSucceeded">
	Suggestion submitted
</kpa-status-card>
<form
	[formGroup]="suggestionForm"
	fxLayout="column"
	fxLayoutAlign="stretch"
	class="kpa-formWrap"
	(keydown.enter)="$event.preventDefault()"
	(ngSubmit)="onSubmit()">
	<div fxLayout="column">
		<div
			class="templateReturnLink"
			*ngIf="isClientAdmin">
			<a
				class="template-link"
				href="{{ mkoUrl }}{{ viewUrl }}"
				><i class="fal fa-less-than"></i>Return to Suggestions List</a
			>
		</div>
		<div
			class="card"
			fxLayout="column">
			<div
				fxLayout="row"
				fxLayoutGap="60px grid"
				fxAlign="center">
				<div
					fxLayout="column"
					fxFlex="0 0 33%">
					<div>
						<kendo-floatinglabel
							id="inpText_firstName_Label"
							text="First Name">
							<input
								kendoTextBox
								id="inpText_firstName"
								formControlName="firstName"
								maxlength="100" />
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inptxt_firstName_ValidationErrors"
							[control]="suggestionForm.get('firstName')">
						</validation-errors>
					</div>
					<div>
						<p
							*ngIf="!isAnonymous"
							class="required-label">
							Required
						</p>
					</div>
					<div>
						<kendo-floatinglabel
							id="inpText_lastName_Label"
							text="Last Name">
							<input
								kendoTextBox
								id="inpText_lastName"
								formControlName="lastName"
								maxlength="100" />
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inptxt_lastName_ValidationErrors"
							[control]="suggestionForm.get('lastName')">
						</validation-errors>
					</div>
					<div>
						<p
							*ngIf="!isAnonymous"
							class="required-label">
							Required
						</p>
					</div>
					<div>
						<label class="search-field">
							<label
								class="k-checkbox-label"
								id="inpCheckboxLabel_submitAnonymously"
								for="inpCheckbox_submitAnonymously">
								Submit Anonymously
							</label>
							<input
								type="checkbox"
								kendoCheckBox
								id="inpCheckbox_submitAnonymously"
								formControlName="submitAnonymously"
								class="k-checkbox check-box" />
						</label>
					</div>
					<div
						class="k-form-field"
						fxLayout="column">
						<span class="dd-label">Department</span>
						<kendo-dropdownlist
							id="inpDepartment"
							appFloatingDropDownlabel
							[data]="departments"
							[(value)]="selectedDepartment"
							[textField]="'departmentName'"
							[valueField]="'id'"
							[defaultItem]="departmentPlaceholder"
							formControlName="accountDepartmentFk"
							class="kendo-dropdown k-dropdown"
							[valuePrimitive]="true"
							name="accountDepartmentFk"
							name="kendo-dropdownDepartment">
						</kendo-dropdownlist>
						<span class="dd-border"></span>
					</div>
					<div>
						<validation-errors
							id="inpSelect_department_ValidationErrors"
							[control]="suggestionForm.get('accountDepartmentFk')">
						</validation-errors>
					</div>
					<div>
						<p class="required-label">Required</p>
					</div>
					<div>
						<kendo-floatinglabel
							id="inpText_location_Label"
							text="Location">
							<input
								kendoTextBox
								id="inpText_location"
								formControlName="location"
								maxlength="100" />
						</kendo-floatinglabel>
					</div>
					<div>
						<kendo-floatinglabel
							id="inpText_suggestionTitle_Label"
							text="Suggestion Title">
							<input
								kendoTextBox
								id="inpText_suggestionTitle"
								formControlName="title"
								maxlength="100" />
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inpText_suggestionTitle_ValidationErrors"
							[control]="suggestionForm.get('title')">
						</validation-errors>
					</div>
					<div>
						<p class="required-label">Required</p>
					</div>
					<div>
						<kendo-floatinglabel
							id="inpText_suggestion_Label"
							text="Suggestion">
							<textarea
								id="inpText_suggestion"
								kendoTextArea
								formControlName="suggestionText"
								maxlength="2000"
								[autoSize]="true"></textarea>
						</kendo-floatinglabel>
					</div>
					<div>
						<validation-errors
							id="inptxt_suggestion_ValidationErrors"
							[control]="suggestionForm.get('suggestionText')">
						</validation-errors>
					</div>
					<div>
						<p class="required-label">Required</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div
		fxLayout="row"
		fxLayoutGap="20px"
		fxLayoutAlign="end center"
		class="submit-align">
		<div fxLayout="column">
			<button
				kendoButton
				kpaPrimaryButton
				id="btn__Prompt_Submit"
				class="standAloneBtn"
				type="submit"
				[disabled]="saveInitiated">
				Submit
			</button>
		</div>
	</div>
</form>
