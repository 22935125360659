<button
	*ngIf="showButton && clientUniversalId"
	type="button"
	kendoButton
	kpaSecondaryButton
	(click)="openPicker()">
	{{ buttonTitle }}
</button>

<kendo-dialog
	*ngIf="pickerOpened && clientUniversalId"
	[minWidth]="500"
	[maxWidth]="700"
	(close)="closePicker()">
	<kendo-dialog-titlebar>
		{{ dialogTitle }}
	</kendo-dialog-titlebar>

	<kendo-listview
		*ngIf="readonly == false"
		class="select-employees"
		[disabled]="isDisabled"
		[data]="loadedEmployees"
		[height]="isMultipicker == true ? 340 : 465"
		(scrollBottom)="fetchEmployees()">
		<ng-template kendoListViewHeaderTemplate>
			<div class="header">
				<kendo-textbox
					[disabled]="isDisabled"
					(valueChange)="nameFilterChanged($event)"
					placeholder="search"></kendo-textbox>
				<div *ngIf="searching == true || selectionLoading == true"><i class="fa-solid fa-loader fa-spin fa-lg"></i></div>
			</div>
		</ng-template>
		<ng-template
			kendoListViewItemTemplate
			let-dataItem="dataItem"
			let-index="index">
			<div
				class="employee-row"
				[ngClass]="{ 'even-row': index % 2 === 1, 'odd-row': index % 2 === 0 }">
				<button
					type="button"
					*ngIf="isEmployeeSelected(dataItem) != true"
					kendoButton
					kpaSecondaryButton
					class="kpa-secondary"
					[class.large]="isMultipicker == false"
					(click)="selectEmployee(dataItem)"
					[disabled]="isMaxLimitReached || isDisabled || selectionLoading == true">
					<span
						*ngIf="isMultipicker"
						class="center-icon">
						<i
							class="fa fa-plus"
							aria-hidden="true"></i>
					</span>
					{{ isMultipicker == false ? 'Select' : '' }}
				</button>

				<button
					type="button"
					*ngIf="isEmployeeSelected(dataItem) == true"
					kendoButton
					kpaPrimaryButton
					class="kpa-secondary"
					[class.large]="isMultipicker == false"
					(click)="removeEmployee(dataItem)"
					[disabled]="isDisabled || selectionLoading == true">
					<span
						*ngIf="isMultipicker"
						class="center-icon">
						<i
							class="fa fa-close"
							aria-hidden="true"></i>
					</span>
					{{ isMultipicker == false ? 'Unselect' : '' }}
				</button>

				<span>{{ dataItem.fullName }}</span>
			</div>
		</ng-template>
		<ng-template
			*ngIf="loadedEmployees.length == 0 && searching == false"
			kendoListViewFooterTemplate>
			<div class="footer">No employees found.</div>
		</ng-template>
	</kendo-listview>

	<kendo-listview
		*ngIf="isMultipicker == true || selectedEmployees?.length > 0"
		[disabled]="isDisabled"
		[data]="selectedEmployees"
		[height]="isMultipicker == true ? 340 : 115">
		<ng-template
			*ngIf="readonly == false || selectionLoading == true"
			kendoListViewHeaderTemplate>
			<div class="header">
				<div
					*ngIf="readonly == false"
					class="header-selected">
					Selected
				</div>
				<div *ngIf="selectionLoading == true"><i class="fa-solid fa-loader fa-spin fa-lg"></i></div>
			</div>
		</ng-template>
		<ng-template
			kendoListViewItemTemplate
			let-dataItem="dataItem"
			let-index="index">
			<div
				class="employee-row"
				[ngClass]="{ 'even-row': index % 2 === 1, 'odd-row': index % 2 === 0 }">
				<button
					type="button"
					*ngIf="isEmployeeSelected(dataItem) == true && readonly == false"
					kendoButton
					kpaPrimaryButton
					class="kpa-secondary"
					[class.large]="isMultipicker == false"
					(click)="removeEmployee(dataItem)"
					[disabled]="isDisabled || selectionLoading == true">
					<span
						*ngIf="isMultipicker"
						class="center-icon">
						<i
							class="fa fa-close"
							aria-hidden="true"></i>
					</span>
					{{ isMultipicker == false ? 'Unselect' : '' }}
				</button>
				<span>{{ dataItem.fullName }}</span>
			</div>
		</ng-template>
		<ng-template
			*ngIf="selectedEmployees.length == 0 && selectionLoading == false"
			kendoListViewFooterTemplate>
			<div class="footer">There are no selected employees.</div>
		</ng-template>
	</kendo-listview>
</kendo-dialog>
