import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'kpa-status-badge',
	templateUrl: './kpa-status-badge.component.html',
	styleUrls: ['./kpa-status-badge.component.scss'],
})
export class KpaStatusBadgeComponent implements OnInit {
	@Input() passing = false;
	@Input() warning = false;
	@Input() failing = false;
	@Input() dueDate = false;
	@Input() pastDue = false;
	@Input() fullLength = false;
	@Input() label = '';
	@Input() subLabel = '';
	@Input() passingBoxyLook = false;
	constructor() {}

	ngOnInit() {}
}
